import { render, staticRenderFns } from "./questionRecord.vue?vue&type=template&id=532f86cc&scoped=true&"
import script from "./questionRecord.vue?vue&type=script&lang=js&"
export * from "./questionRecord.vue?vue&type=script&lang=js&"
import style0 from "./questionRecord.vue?vue&type=style&index=0&id=532f86cc&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "532f86cc",
  null
  
)

export default component.exports